<template>
  <b-row class="filters-container mb-3">
    <b-col cols="4">
      <label for="status">Status</label>
      <Autocomplete
        id="status"
        v-model="filters.status"
        :options="status"
        placeholder="Selecionar"
        class="w-100"
      />
    </b-col>

    <b-col cols="4">
      <label for="extract_number">Nº do extrato</label>
      <b-form-input
        type="search"
        autocomplete="off"
        v-model="filters.extract_number"
        placeholder="Selecionar"
        class="w-100"
        id="extract_number"
      />
    </b-col>

    <b-col cols="4">
      <BeneficiarySelect
        v-model="beneficiary"
        :value="beneficiary"
        label="Favorecido"  
      />
    </b-col>
  </b-row>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'Extracts',
  components: {
    Autocomplete: () => import('@/components/Autocomplete'),
    BeneficiarySelect: () => import('@/components/General/BeneficiarySelect'),
  },
  data() {
    return {
      clinic: getCurrentClinic(),

      filters: {
        status: null,
        extract_number: null,
        beneficiary_id: null
      },
      beneficiary: null,

      status: [
        {
          label: 'A conferir',
          value: 'A conferir'
        },
        {
          label: 'Conferido',
          value: 'Conferido'
        },
        {
          label: 'Enviado',
          value: 'Enviado'
        },
        {
          label: 'A revisar',
          value: 'A revisar'
        },
        {
          label: 'A pagar',
          value: 'A pagar'
        },
        {
          label: 'Excluído',
          value: 'Excluído'
        },
        {
          label: 'Finalizado',
          value: 'Finalizado'
        }
      ]
    }
  },
  methods: {
    clearBeneficiary(){
      this.beneficiary = null
      this.filters.beneficiary_id = null
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('changeFilters', newValue)
      },
      deep: true
    },    
    beneficiary: {
      handler(newValue) {
        this.filters.beneficiary_id = newValue ? newValue.id : null
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-container {
  .search-container {
    display: flex;
    flex-direction: row;
    position: relative;

    .icon-box {
      padding-right: 5px;
      .icon {
        position: absolute;
        width: 24px;
        height: 24px;
        stroke: var(--neutral-500);
        height: 100%;
        margin-left: 15px;
      }
    }

    input {
      padding-left: 40px !important;
    }
  }
}

label{
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--type-active);
  margin-bottom: 4px;
}

.radios{
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: var(--type-active);
}


.close-icon {
  width: 25px;
  height: 25px;
  fill: rgb(127, 145, 210);
  position: absolute;
  right: 15px;
  top: 47%;
}
</style>